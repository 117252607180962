

.modal_width{
    max-width: none!important;
    width: 90%;
}

.modal_width_full{
  max-width: none!important;
  width: 100%;
  margin-top: 0;
}

.custom_scrollbar{
    overflow-y: hidden;
    overflow-x: scroll;
  }

.custom_scrollbar::-webkit-scrollbar{
    height: 6px;
    
  }

  
  
  .custom_scrollbar::-webkit-scrollbar-button {
    background: #ccc;
  }
  .custom_scrollbar::-webkit-scrollbar-track-piece {
    background: #f2f2f2;
  }
  .custom_scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #ccc;
  }


  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 30px;
    font-weight: bold;
    transition: 0.3s;
    background: transparent;
    border-width: 0;
  }
  
  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }


  .jodit-editor__resize{
    display: none;
  }

  .config_layout{
    cursor: pointer;
  }

  .config_layout:hover{
    color: grey;
  }