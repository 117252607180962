@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,400;0,600;1,100;1,400;1,600&display=swap');

$sidebar-bg-color: #1d1d1d;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(29, 29, 29, 0.95);
$sidebar-color: #adadad;
$highlight-color: #d8d8d8;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;

@import "~react-pro-sidebar/dist/scss/styles.scss";
body {
  margin: 0;
  // height: 100vh;
  color: #353535;
  font-family: 'Poppins', sans-serif;
}

.btn, .form-control {
  padding: 0.6rem 1.2rem;
  border-radius: 0.5rem;
}

.MuiRadio-colorSecondary.Mui-checked, .MuiSwitch-colorSecondary.Mui-checked {
  color: rgba(12, 34, 84, 0.8)!important;
}

.MuiIconButton-colorSecondary {
  color: rgba(12, 34, 84, 0.8)!important;
}

.MuiSwitch-track {
  background-color: rgba(12, 34, 84, 0.8)!important;
}

thead {
  border-bottom: 1px solid #c7c7c7!important;
}

th {
  background-color: #ececf1!important;
  padding: 0.8rem 0.75rem!important;
  color: rgb(58, 58, 58)!important;
} 

table {
  margin-bottom: 0!important;
}

::-webkit-scrollbar {
  /* width: 0.875rem; */
  width: 1.1rem;
  height: 1.125rem;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  height: 0.375rem;
  border: 0.372rem solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 0.438rem;
  border-radius: 0.5rem;
  background-color: #c7c7c7;
  -webkit-box-shadow: inset -1px -1px 0px rgb(0 0 0 / 5%), inset 1px 1px 0px rgb(0 0 0 / 5%);
  box-shadow: inset -1px -1px 0px rgb(0 0 0 / 5%), inset 1px 1px 0px rgb(0 0 0 / 5%);
}

#root {
  height: 100%;
}

.app {
  height: 100%;
  display: flex;
  position: relative;

  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
  }

  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .sidebar-btn {
      padding: 1px 15px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.05);
      color: #adadad;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        margin-left: 5px;
        font-size: 13px;
      }
      &:hover {
        color: $highlight-color;
      }
    }
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      line-height: 40px;
      padding: 0;
    }
  }

  main {
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    header {
      margin-bottom: 40px;
      border-bottom: 1px solid #efefef;
      h1 {
        display: flex;
        align-items: center;
        transform: translateX(-20px);
      }
    }

    footer {
      margin-top: auto;
      color: #888;
      text-align: center;
      a {
        text-decoration: none;
        color: #888;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .social-bagdes {
    margin-top: 10px;
    img {
      margin: 5px;
    }
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;
    > span {
      margin-left: 10px;
    }
  }

  &.rtl {
    direction: rtl;
    text-align: right;

    header {
      h1 {
        transform: translateX(20px);
      }
    }
    .block {
      direction: rtl;
      > span {
        margin-left: 0;
        margin-right: 10px;
      }
    }
    .sidebar-btn-wrapper {
      .sidebar-btn {
        span {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }
  }

  @media (max-width: 767.98px) {
    overflow-x: hidden;
    header {
      h1 {
        font-size: 24px;
      }
    }
  }
  @media (max-width: $breakpoint-md) {
    .btn-toggle {
      display: flex;
    }

    &.rtl {
      .btn-toggle {
        margin-left: auto;
      }
    }
  }
}

.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;
  &.red {
    color: #ffffff;
    background: #d63030;
  }
  &.gray {
    color: #ffffff;
    background: #777777;
  }
  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}

.pro-sidebar-layout::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.pro-sidebar-layout {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.pro-item-content {
  user-select: none;
}
attention {
  color: red;
  font-weight: bolder;
}

:root {
  --muted: #353535;
  --gray-blue: #34495e;
  --dark-gray-blue: #2c3e50;
}

button:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

table {
  text-align: center;
}

thead {
  border-bottom: 1px solid black;
}

th {
  padding: 5px 0;
}

.nocursor {
  cursor: none;
}

.remove-a {
  color: black;
  text-decoration: none;
}

.remove-a:hover {
  color: black;
}

.children-margin-bottom > * {
  margin-bottom: 1em;
}

.title {
  font-weight: 700;
}

.header {
  font-weight: 700;
}

.font-light {
  font-weight: lighter;
}

.text-event {
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
}

.overflow-hidden {
  overflow: hidden;
}

.break-word {
  word-wrap: break-word;
}

.form-label {
  font-weight: bold;
}

.text-center,
*[text-center] {
  text-align: center;
}

.rounded-right {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.margin-0 {
  margin: 0;
}

.margin-bottom {
  margin-bottom: 1em;
}

.hide {
  display: none !important;
}

.show {
  display: initial;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-wrap,
*[wrap] {
  flex-wrap: wrap;
}

.flex-nowrap,
*[nowrap] {
  flex-wrap: nowrap;
}

.align-center {
  align-items: center;
}

.jcc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between !important;
}

.jcsa {
  justify-content: space-around;
}

.jcse {
  justify-content: space-evenly;
}

.jsstart {
  justify-content: flex-start;
}

.jsend {
  justify-content: flex-end;
}

.fdrow {
  flex-direction: row;
}

.fdcolumn {
  flex-direction: column;
}

.w100 {
  width: 100%;
}

.wauto {
  width: auto;
}

.flex1 {
  flex: 1;
}

.flex2 {
  flex: 2;
}

.flex3 {
  flex: 3;
}

.icon {
  cursor: pointer;
}

.icon:hover {
  filter: brightness(0.5);
  transition: 0.2s ease-in-out;
  transform: scale(1.1);
}

.nonclick {
  pointer-events: none;
}

.table-cell {
  display: table-cell;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar-custom {
  background-color: #ececf1;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0 10px 0 0;
  margin-left: 240px;
  min-height: 70px;
}

.topbar {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
}

.topbar .topbar-left {
  background: #1893d6;
  float: left;
  text-align: center;
  height: 70px;
  position: relative;
  width: 240px;
  z-index: 1;
}

.content-page {
  width: 100%;
  background: #f8f8fa;
  min-height: 100vh;
}

.pagination {
  /* display: inline-block; */
  padding-left: 15px;
  padding-right: 15px;
}

.pagination li {
  display: inline-block;
  padding-left: 10px;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid lightgrey;
  cursor: pointer;
}

.pagination li.previous:not(.disabled) {
  background-color: #73b054;
  color: white;
}

.pagination li.previous:not(.disabled) a {
  color: white;
}

.pagination li.next:not(.disabled) {
  background-color: #73b054;
}

.pagination li.next:not(.disabled) a {
  color: white;
}

.pagination li.active {
  background-color: #73b054;
  color: white;
}

.pagination li.active a {
  color: white;
}

.tab {
  min-height: 50px;
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #dcdcdc #dcdcdc white;
  text-align: center;
  font-size: 1.2rem;
  color: black;
  margin-right: 2px;
}

.opcao_autocomplete {
  padding: 10px 10px 10px 10px;
  background-color: white;
  cursor: pointer;
}
.opcao_autocomplete:hover {
  background-color: #f2f2f2;
}

.col-form-label {
  margin-top: 10px;
  font-weight: bold;
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.header-title {
  font-weight: bold;
}

.map2 {
  position: relative !important;
  height: 100%;
}

.hr_show {
  display: block;
}

@media (max-width: 992px) {
  .hr_show {
    display: none;
  }
}

@media (max-width: 576px) {
  .hr_show {
    display: block;
  }
}

p {
  letter-spacing: 0.2px;
}

.jodit-wysiwyg {
  outline: 0;
  /* overflow-x: auto; */
}

#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.nav-active {
  font-weight: bold;
  color: white !important;
}
