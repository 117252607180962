.dashboardTable {
  background-color: white;
  border-radius: 0 0 0.4rem 0.4rem;
  thead {
    border-bottom: 1px solid #b6b6b6;
  }

  tr {
    &:hover {
      background-color: #f5f5f5;
    }
    td {
      padding: 0.4rem 0.4rem;
      border-bottom: 1px solid #e7e7e7;

    }
    .link {
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: #007bff;
        text-decoration: underline;
      }
    }
  }
}

.summaryItem {
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 1.4rem;
  border-radius: 0.6rem;
  width: max-content;
  gap: 1.2rem;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.06);
  }
}
